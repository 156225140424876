<template>
  <!--
    The view for the InstallationConfig-component
  -->
  <Portlet
    :title="$t('menu.installationConfiguration')"
    icon="cogs"
  >
    <template slot="buttons">
      <button
        class="btn btn-sm btn-secondary mr-3"
        @click="showRefreshAllSidebar()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        />{{ $t('installationConfigurationRefresh.refreshAll') }}
      </button>
      <button
        class="btn btn-sm btn-primary float-right mt-2 mb-2"
        @click="addInstallationConfig()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
    </template>
    <InstallationConfigList
      ref="installationConfigList" 
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-installation-config="true"
      @reload="reloadCutConfigList"
    /> 
    <Sidebar
      v-if="showRefreshAllSidebarBoolean"
      :show-sidebar="showRefreshAllSidebarBoolean"
      :sidebar-width="600"
      @close="hideRefreshAllSidebar"
    >
      <InstallationConfigRefreshAll
        @refresh="hideRefreshAllSidebar"
      />
    </Sidebar>
  </Portlet>
</template>

<script>
export default {
  name: "InstallationConfig",
  components: {
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    InstallationConfigList: () => import('@/components/VideoPostprocessing/InstallationConfig/InstallationConfigList.vue'),
    InstallationConfigRefreshAll: () => import('@/components/VideoPostprocessing/InstallationConfig/InstallationConfigRefreshAll.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  data () {
    return {
      showRefreshAllSidebarBoolean: false
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadCutConfigList () {
      this.$refs.installationConfigList.refreshAndClose();
    },
    addInstallationConfig () {
      this.$refs.installationConfigList.addInstallationConfig();
    },
    showRefreshAllSidebar () {
      this.showRefreshAllSidebarBoolean = true;
    },
    hideRefreshAllSidebar () {
      this.showRefreshAllSidebarBoolean = false;
    },
  }
}
</script>
